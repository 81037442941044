<template>
    <div class="full-height-layout fill" data-test="supplierImportProductStepConfirmation">
        <div class="fill mt-5">
            <div class="row" v-if="hasData">
                <div class="col col-12 pl-15 pr-15" v-if="isGeneratingPackageConfiguration">
                    <alert type="info">
                        Waiting for package configuration to be generated! Once this is done, you can publish your product!
                    </alert>
                </div>
                <div class="col col-12 pl-15 pr-15" v-else-if="isPackageGenerationRecommended">
                    <alert type="accent">
                        Package configuration is recommended to be regenerated before publishing the product.
                    </alert>
                </div>
                <div :class="columnClass">
                    <mercur-card class="h-100">
                        <div class="box-header">
                            <h2>Product name</h2>
                        </div>

                        <strong>{{ supplierProductImportedDetails.productName }}</strong>
                        <p v-if="supplierProductImportedDetails.categoryId && categories"><strong>Product category:
                            {{ computedCategoryName }}</strong></p>
                        <p>Attribute configuration</p>
                        <ul v-for="(selectedAttribute, selectedAttributeKey) in supplierProductImportedDetails.attributeConfiguration"
                            :key="selectedAttributeKey">
                            <li>
                                <strong>{{ beautifyValue(selectedAttributeKey) }}</strong>
                                <ul>
                                    <li class="selected-option" v-for="(selectedOption, index) in selectedAttribute"
                                        :key="index">
                                        <template v-if="selectedOption">
                                            option: {{ beautifyValue(selectedOption.option) }} <br>
                                            alternative: {{ beautifyValue(selectedOption.alternative) }}
                                        </template>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </mercur-card>
                </div>
                <div :class="columnClass">
                    <mercur-card class="h-100"
                                 :class="{'is-invalid': $v.supplierProductImportedDetails.columnMapping.$invalid}">
                        <div class="box-header">
                            <h2>Attribute Column mapping</h2>
                        </div>
                        <div>
                            <ul v-for="(attributeValue, index) in supplierProductImportedDetails.columnMapping"
                                :key="index">
                                <li>
                                    <strong>{{ beautifyValue(attributeValue.columnNames[0]) }}</strong><br/>
                                    <span>Type: </span> {{ attributeValue.type }} <br>
                                    <strong>Mapped To:</strong>
                                    <ul>
                                        <li class="selected-option" v-for="(val, key) in attributeValue.mappedTo"
                                            :key="key">
                                            <strong>{{ beautifyValue(key) }}</strong><br>
                                            <span>Type: </span> {{ val.type }} <br>
                                            <span>Value: </span> {{ val.value }} <br>
                                            <span>Connection Name: </span>: {{ val.connectionName }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </mercur-card>
                </div>
                <div :class="columnClass">
                    <mercur-card class="h-100" :class="{ 'is-invalid': !supplierProductImportedDetails.valueMapping }">
                        <div class="box-header">
                            <h2>Attribute Value mapping</h2>
                        </div>
                        <div>
                            <ul v-for="(attributeValue, attributeKey) in supplierProductImportedDetails.valueMapping"
                                :key="attributeKey">
                                <li>
                                    <strong>{{ beautifyValue(attributeValue.columnNames[0]) }}</strong><br/>
                                    <ul v-for="(val, key) in attributeValue.values" :key="key">
                                        <li>
                                            <strong>{{ beautifyValue(key) }}</strong>
                                            <ul v-for="(innerVal, innerKey) in val" :key="innerKey">
                                                <li v-if="innerVal">
                                                    <strong>{{ beautifyValue(innerKey) }}</strong><br>
                                                    <span>Option: </span> {{ innerVal.option }} <br>
                                                    <span>Postfix: </span> {{ innerVal.postfix }} <br>
                                                    <span>Configuration: </span> {{ innerVal.configuration }} <br>
                                                    <span>Additional data: </span> {{ innerVal.additionalData }}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </mercur-card>
                </div>
                <div :class="columnClass">
                    <mercur-card class="h-100">
                        <div class="box-header">
                            <h2>Facilities</h2>
                        </div>
                        <ul>
                            <li v-for="facility in supplierProductImportedDetails.facilities"
                                :key="facility.locationId">
                                <strong>{{ facility.locationName }}</strong><br/>
                                <span
                                    v-if="facility.productionDays">Production days: {{
                                        facility.productionDays.flatMap(({days}) => days).join(', ')
                                    }}</span>
                            </li>
                        </ul>
                    </mercur-card>
                </div>
                <div :class="columnClass">
                    <mercur-card class="h-100"
                                 :class="{ 'is-invalid': !supplierProductImportedDetails.packageConfiguration }">
                        <div class="box-header">
                            <h2>Packages</h2>
                        </div>
                        <div>
                            <ul v-for="(item, index) in supplierProductImportedDetails.packageConfiguration"
                                :key="index">
                                <li>
                                    <strong>Attributes</strong><br/>
                                    <ul>
                                        <li v-for="(attributeValue, attributeKey) in item.attributes"
                                            :key="attributeKey">
                                            <strong>{{ beautifyValue(attributeKey) }}</strong><br>
                                            <ul>
                                                <li v-for="(val, key) in attributeValue" :key="key">
                                                    {{ beautifyValue(val) }}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Configurations</strong>
                                    <ul>
                                        <li v-for="(value, index2) in item.configurations" :key="index2">
                                            <span>Package name: </span> {{ value.packageName }} <br>
                                            <span>Package type: </span> {{ value.packageType }} <br>
                                            <span>Width: </span> {{ value.packageWidth }} <br>
                                            <span>Height: </span> {{ value.packageHeight }} <br>
                                            <span>Length: </span> {{ value.packageLength }} <br>
                                            <span>Max units  per package: </span> {{ value.maxUnitsPerPackage }} <br>
                                            <span>Weight: </span> {{ value.weight }} <br>
                                            <span>Weight tare: </span> {{ value.weightTare }} <br>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </mercur-card>
                </div>
                <div :class="columnClass">
                    <mercur-card class="h-100"
                                 :class="{ 'is-invalid': !supplierProductImportedDetails.cutofftimeConfiguration }">
                        <div class="box-header">
                            <h2>Cut off configuration</h2>
                        </div>
                        <div>
                            <ul v-for="(cutoff, index) in supplierProductImportedDetails.cutofftimeConfiguration"
                                :key="index">
                                <li>
                                    <strong>Countries</strong><br/>
                                    {{ cutoff.countries.join(', ') }}
                                </li>
                                <li>
                                    <strong>Attributes</strong><br/>
                                    <ul>
                                        <li v-for="(attributeValue, attributeKey) in cutoff.attributes"
                                            :key="attributeKey">
                                            <strong>{{ beautifyValue(attributeKey) }}</strong><br/>
                                            <span>Attribute value:</span> {{ attributeValue }}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Cutoff time</strong><br/>
                                    <ul>
                                        <li>Time: {{ cutoff.cutoffTime.time }}</li>
                                        <li v-if="cutoff.cutoffTime.daysOfWeek">Days of week
                                            {{ cutoff.cutoffTime.daysOfWeek.join(',') }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </mercur-card>
                </div>
                <div :class="columnClass">
                    <mercur-card class="h-100"
                                 :class="{ 'is-invalid': !supplierProductImportedDetails.artworkConfiguration }">
                        <div class="box-header">
                            <h2>Artwork configuration</h2>
                        </div>
                        <div>
                            <p v-if="supplierProductImportedDetails.artworkConfiguration && supplierProductImportedDetails.artworkConfiguration.artworkSkipped">
                                Artwork was skipped</p>
                            <template v-else>
                                <ul v-for="(item, index) in supplierProductImportedDetails.artworkConfiguration"
                                    :key="index">
                                    <li>
                                        <strong>Configuration</strong><br/>
                                        <ul>
                                            <li v-for="(val, key) in item.configuration" :key="key">
                                                <strong>{{ key }}:</strong> {{ val }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </mercur-card>
                </div>
            </div>
        </div>
        <div class="flex pl-15 pr-15 mt-5">
            <div class="error-message">
                <p v-if="validationErrors.length" data-test="supplierImportProductConfirmationErrorMessage"
                   class="flex-1">Product has invalid: {{ validationErrors.join(', ') }}</p>
            </div>
            <div class="align-right">
                <mercur-button class="btn btn-yellow" :disabled="buttonsDisabled || isCheckingProduct"
                               @click="checkImportedProductChanges()">Check changes
                </mercur-button>
                <mercur-button class="btn btn-yellow" :disabled="buttonsDisabled || isSavingProduct"
                               @click="$emit('save', payload)">Save
                </mercur-button>
                <mercur-button class="btn btn-yellow" :disabled="buttonsDisabled || !canPublish"
                               @click="$emit('publish', payload)" data-test="supplierImportProductConfirmationPublish">
                    Publish
                </mercur-button>
            </div>
        </div>
        <br>
        <mercur-dialog :is-open.sync="displayChangesDialog" width="900px">
            <div slot="header">
                <h3 class="font-weight-normal">Check changes</h3>
            </div>
            <div slot="default">
                <supplier-imported-product-changes
                    :changes="checkProductChangesResult"></supplier-imported-product-changes>
            </div>
            <div slot="footer">
                <mercur-button class="btn text-uppercase" @click="displayChangesDialog = false">close</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import SupplierImportedProductMixin, { validations } from './SupplierImportedProductMixin'
import Alert from '../../../components/Alert'
import { mapState } from 'vuex'
import SupplierImportedProductChanges from './SupplierImportedProductChanges'

export default {
    name: 'SupplierImportedProductConfirmation',
    mixins: [SupplierImportedProductMixin],
    components: { SupplierImportedProductChanges, Alert },
    props: [
        'getUrlForStep',
        'isPublishing',
    ],
    data () {
        return {
            columnClass: 'col col-4 mb-10 mt-10',
            displayChangesDialog: false,
            checkProductChangesResult: {
                everySkuChanged: false,
                addedSkus: [],
                removedSkus: [],
                addedAttributes: [],
                removedAttributes: [],
            },
            isCheckingProduct: false,
        }
    },
    validations () {
        return {
            supplierProductImportedDetails: {
                columnMapping: validations.columnMapping,
            },
        }
    },
    methods: {
        beautifyValue (value) {
            if (!value) {
                return value
            }
            return value.toString().charAt(0).toUpperCase() + value.slice(1).replace(/_/g, ' ')
        },
        async checkImportedProductChanges () {
            if (!this.hasData) {
                return
            }
            this.$emit('execute', {
                fn: async () => {
                    const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.CHECK_CHANGES
                        .replace('{supplierId}', this.supplierProductImportedDetails.supplierId)
                        .replace('{supplierProductImportedId}', this.supplierProductImportedDetails.supplierProductImportedId)
                    try {
                        this.isCheckingProduct = true
                        this.addJob(url)
                        const data = await this.$api.get(url)
                        this.checkProductChangesResult = data.data.data
                        this.displayChangesDialog = true
                    } finally {
                        this.finishJob(url)
                        this.isCheckingProduct = false
                    }
                },
                errorMessage: 'Product check failed. Please try again.',
            })
        },
    },
    computed: {
        ...mapState('productContent', ['categories']),
        buttonsDisabled () {
            return this.isLoading || this.isCheckingProduct || this.isGeneratingPackageConfiguration || this.isSavingProduct || !!(this.validationErrors || []).length
        },
        payload () {
            return this.supplierProductImportedDetails
        },
        hasData () {
            return !!this.payload
        },
        canPublish () {
            if (!this.hasData) {
                return false
            }

            if (this.isGeneratingPackageConfiguration) {
                return false
            }

            if (this.validationErrors.length) {
                return false
            }

            return !this.isPublishing
        },
        isGeneratingPackageConfiguration () {
            if (!this.hasData) {
                return false
            }
            return this.supplierProductImportedDetails.productImportStatus === 'GENERATING_PACKAGE_CONFIGURATION'
        },
        isPackageGenerationRecommended () {
            if (!this.hasData) {
                return false
            }
            return this.supplierProductImportedDetails.packageGenerationRecommended === true
        },
        computedCategoryName () {
            if (!this.categories || !this.hasData) {
                return ''
            }
            const category = this.categories.find(e => e.productCategoryId === this.supplierProductImportedDetails.categoryId)
            if (category) {
                return category.categoryName
            }

            return ''
        },
        validationErrors () {
            const missingFields = []
            if (!this.hasData) {
                return []
            }

            if (this.$v.supplierProductImportedDetails.columnMapping.$invalid) {
                missingFields.push('column mapping')
            }
            if (!this.supplierProductImportedDetails.columnMapping) {
                missingFields.push('column mapping')
            }
            if (!this.supplierProductImportedDetails.attributeConfiguration) {
                missingFields.push('attributes')
            }
            if (!this.supplierProductImportedDetails.valueMapping) {
                missingFields.push('value mapping')
            }
            if (!this.supplierProductImportedDetails.facilities) {
                missingFields.push('facilities')
            }
            if (!this.supplierProductImportedDetails.packageConfiguration) {
                missingFields.push('package configuration')
            }
            if (!this.supplierProductImportedDetails.cutofftimeConfiguration) {
                missingFields.push('cutoff time configuration')
            }
            if (!this.supplierProductImportedDetails.artworkConfiguration) {
                missingFields.push('artwork configuration')
            }
            return Array.from(new Set(missingFields))
        },
    },
    created () {
        if (!this.categories) {
            this.$store.dispatch('productContent/getProductContentCategories')
        }
    },
}
</script>

<style lang="scss" scoped>
.is-invalid {
    box-shadow: red 0 0 6px 0;
}

.error-message {
    flex: 1;
    display: flex;
    align-items: center;
    color: red;
}
</style>
